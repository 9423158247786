import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../Config/firebase";

const handleClearingPhotoGalleryForm = (
  setProjectTitle,
  setProjectDescript,
  setProjectBeforeImage,
  setBeforeImageUrl,
  setAfterImageUrl,
  setProjectAfterImage,
  setSubmitting,
  setSuccessfulSubmission
) => {
  setProjectTitle("");
  setProjectDescript("");
  setProjectBeforeImage(null);
  setProjectAfterImage(null);
  setBeforeImageUrl("");
  setAfterImageUrl("");
  setSubmitting(false);
  return setSuccessfulSubmission(true);
};

const handleUploadingImages = async (
  projectTitle,
  setProjectTitle,
  projectDescript,
  setProjectDescript,
  projectBeforeImage,
  setProjectBeforeImage,
  setBeforeImageUrl,
  setAfterImageUrl,
  projectAfterImage,
  setProjectAfterImage,
  setSubmitting,
  setErrors,
  setSuccessfulSubmission
) => {
  setErrors(false);

  if (!projectBeforeImage || !projectAfterImage) {
    setSubmitting(false);
    return setErrors(true);
  }

  const beforeImageQuery = ref(
    storage,
    `beforeImage/${projectBeforeImage.name}`
  );
  const afterImageQuery = ref(storage, `afterImage/${projectAfterImage.name}`);

  await uploadBytes(beforeImageQuery, projectBeforeImage)
    .then((beforeImageSnapshot) => {
      return getDownloadURL(beforeImageSnapshot.ref);
    })
    .then((beforeImageDownloadURL) => {
      return setBeforeImageUrl(beforeImageDownloadURL);
    })
    .catch((err) => {
      setSubmitting(false);
      return setErrors([err.message]);
    });

  await uploadBytes(afterImageQuery, projectAfterImage)
    .then((afterImageSnapshot) => {
      return getDownloadURL(afterImageSnapshot.ref);
    })
    .then((afterImageDownloadURL) => {
      return setAfterImageUrl(afterImageDownloadURL);
    })
    .catch((err) => {
      setSubmitting(false);
      return setErrors([err.message]);
    });
};

const handleUploadingToDatabase = (
  projectTitle,
  setProjectTitle,
  projectDescript,
  setProjectDescript,
  setProjectBeforeImage,
  setBeforeImageUrl,
  setAfterImageUrl,
  beforeImageUrl,
  afterImageUrl,
  setProjectAfterImage,
  setSubmitting,
  setErrors,
  projectBeforeImage,
  projectAfterImage,
  setSuccessfulSubmission
) => {
  const data = {
    beforeImgPathName: `beforeImage/${projectBeforeImage.name}`,
    afterImgPathName: `afterImage/${projectAfterImage.name}`,
    beforeImgUrl: beforeImageUrl,
    afterImgUrl: afterImageUrl,
  };

  const collectionRef = collection(db, "photo-gallery");
  // use addDoc method
  addDoc(collectionRef, data)
    .then(() => {
      handleClearingPhotoGalleryForm(
        setProjectTitle,
        setProjectDescript,
        setProjectBeforeImage,
        setBeforeImageUrl,
        setAfterImageUrl,
        setProjectAfterImage,
        setSubmitting,
        setSuccessfulSubmission
      );
    })
    .catch((err) => {
      // clear loading submission state
      setSubmitting(false);
      // if error display to user
      return setErrors([err.message]);
    });
};

export { handleUploadingImages, handleUploadingToDatabase };
