import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Config/firebase";

const handleClearingBookingApptForm = (
  setName,
  setEmail,
  setPhoneNum,
  setAddress,
  setDate,
  setLookingFor,
  setLoadingSubmission,
  setSuccessfulSubmission
) => {
  setLoadingSubmission(false);
  setName("");
  setEmail("");
  setPhoneNum("");
  setAddress("");
  setDate(null);
  setLookingFor("");

  return setSuccessfulSubmission(true);
};

const handleSubmitBookingApptForm = (
  name,
  setName,
  email,
  setEmail,
  phoneNum,
  setPhoneNum,
  address,
  setAddress,
  date,
  setDate,
  lookingFor,
  setLookingFor,
  errors,
  setErrors,
  loadingSubmission,
  setLoadingSubmission,
  successfulSubmission,
  setSuccessfulSubmission
) => {
  setErrors(false);

  if (
    name.length < 3 ||
    email.length < 3 ||
    phoneNum.length < 10 ||
    address.length < 3 ||
    lookingFor.length < 1 ||
    !date 
  ) {
    // clear loading state
    setLoadingSubmission(false);
    // display errors to user
    return setErrors(true);
  }

  const userFriendlyDate = `${date.toDateString()}`;

  // data to send
  const data = {
    name,
    email,
    phoneNum,
    address,
    lookingFor,
    date: `${userFriendlyDate}`,
  };

  const collectionRef = collection(db, "booked-appointments");
  // use addDoc method
  addDoc(collectionRef, data)
    .then(() => {
      // if successful clear the form
      handleClearingBookingApptForm(
        setName,
        setEmail,
        setPhoneNum,
        setAddress,
        setDate,
        setLookingFor,
        setLoadingSubmission,
        setSuccessfulSubmission
      );
    })
    .catch((err) => {
      // clear loading submission state
      setLoadingSubmission(false);
      // if error display to user
      return setErrors([err]);
    });
};

export { handleSubmitBookingApptForm };
