import React from 'react';
import { NavLink } from 'react-router-dom';
import LawnCareImage from "../../Images/Landscape/landscape-8.jpeg"
import SnowRemovalImage from "../../Images/Landscape/landscape-1.jpeg"

const MaintenanceAndSnowRemoval = () => {
  return (
    <div className="service-container">
      <div className="service-intro-container">
        <div className="service-intro-wrapper">
          <div className="service-intro-header">
            Maintenance & Snow Removal Services for Your Property
          </div>

          <div className="service-intro-text">
            Keeping your outdoor space looking its best requires regular maintenance, and our maintenance & snow removal services can help you achieve that. Our team of professionals has the expertise and equipment necessary to keep your lawn, garden beds, and outdoor space looking healthy and vibrant year-round. Additionally, we offer reliable snow removal services to ensure your property remains safe and accessible during the winter months.
          </div>
        </div>

        <div className="service-intro-image-wrapper">
          <img
            className="service-intro-image"
            src={LawnCareImage}
          />
        </div>
      </div>

      <div className="services-main-content-container">
        <div className="services-main-content-intro-wrapper">
          <div className="services-main-content-intro-header">
            Comprehensive Lawn Maintenance Services
          </div>

          <div className="services-main-content-intro-text">
            We offer a comprehensive range of lawn maintenance services so you can keep your property looking great and functioning well. Our team of experts will work with you to create a customized maintenance plan that meets your specific needs and budget.
          </div>
        </div>

        <div className="services-main-content-wrapper">
          <div className="service-wrapper">
            <div className="service-header">
              Lawn Maintenance
            </div>

            <div className="service-text">
              Our lawn maintenance services include mowing, fertilizing, weed control, and aeration to keep your lawn looking healthy and green.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Garden Bed Maintenance
            </div>

            <div className="service-text">
              Our garden bed maintenance services include weeding, pruning, mulching, and plant replacement to keep your garden beds looking their best.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Sod & Artificial Turf Maintenance
            </div>

            <div className="service-text">
              We offer maintenance services for both sod and artificial turf, including fertilization, aeration, and regular care to keep your lawn looking its best.
            </div>
          </div>
        </div>
      </div>

      <div className="alt-services-main-content-container" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "40px" }}>
        <div className="services-main-content-container services-main-content-container-alt">
          <div className="services-main-content-intro-wrapper">
            <div className="services-main-content-intro-header">
              Comprehensive Snow Removal Services
            </div>

            <div className="services-main-content-intro-text">
              We offer a comprehensive range of snow removal services can keep your property looking great and functioning well.
            </div>
          </div>

          <div className="services-main-content-wrapper alt-services-main-content-wrapper">
            <div className="service-wrapper">
              <div className="service-header">
                Snow Removal
              </div>

              <div className="service-text">
                Our snow removal services include plowing, shoveling, and de-icing to keep your property safe and accessible during the winter months.
              </div>
            </div>
          </div>
        </div>

        <div className="services-alt-section-image-wrapper">
          <img
            className="services-alt-section-image"
            src={SnowRemovalImage}
          />
        </div>
      </div>

      <div className="service-outro-wrapper">
        <div className="service-outro-header">
          Contact Us Today for Your Maintenance & Snow Removal Needs!
        </div>

        <div className="service-outro-text">
          At our E&O Landscape, we are committed to providing high-quality maintenance and snow removal services that keep your property looking great and functioning well. Whether you need regular lawn maintenance or reliable snow removal, our team of experts is ready to help. Contact us today to schedule a consultation and find out how we can keep your property in top shape all year round.
        </div>

        <NavLink
          to="/book-consultation"
          className="service-outro-action-button"
        >
          Book Now
        </NavLink>
      </div>
    </div>
  )
}

export default MaintenanceAndSnowRemoval;