import React from "react";
import { NavLink } from "react-router-dom";
import LandscapingIntroImage from "../../Images/Landscape/landscape-13.jpg"

const OutdoorConstructionAndLandscaping = () => {
  return (
    <div className="service-container">
      <div className="service-intro-container">
        <div className="service-intro-wrapper">
          <div className="service-intro-header">
            Outdoor Construction and Landscaping Services
          </div>

          <div className="service-intro-text">
            If you're looking to enhance your outdoor space with beautiful and functional features, our outdoor construction and landscaping services can help you achieve your dream. Our team of experts has years of experience in the industry and uses only the highest quality materials and techniques to create stunning outdoor environments. Whether you're looking to create a relaxing oasis or a functional entertainment area, we can design and build custom solutions tailored to your unique needs and preferences.
          </div>
        </div>

        <div className="service-intro-image-wrapper">
          <img
            className="service-intro-image"
            src={LandscapingIntroImage}
          />
        </div>
      </div>

      <div className="services-main-content-container">
        <div className="services-main-content-intro-wrapper">
          <div className="services-main-content-intro-header">
            Comprehensive Outdoor Construction and Landscaping Services
          </div>

          <div className="services-main-content-intro-text">
            From hardscape patios to masonry, from pergolas to outdoor kitchens, our comprehensive range of outdoor construction and landscaping services can help you create the perfect outdoor space for your lifestyle. Our team of experts will work with you every step of the way, from concept to completion, to ensure that your project is completed on time, on budget, and to your satisfaction.
          </div>
        </div>

        <div className="services-main-content-wrapper">
          <div className="service-wrapper">
            <div className="service-header">
              Hardscape Patio
            </div>

            <div className="service-text">
              Our hardscape patio services include design and installation of durable and stylish patios that add functional and aesthetic value to your outdoor space.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Masonry
            </div>

            <div className="service-text">
              We offer custom masonry services, including stone, brick, and concrete work, to create stunning features such as fireplaces, retaining walls, and walkways.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Pergola
            </div>

            <div className="service-text">
              Our pergola services include custom design and installation of stylish and functional pergolas that provide shade and enhance the beauty of your outdoor space.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Decks
            </div>

            <div className="service-text">
              We specialize in the design and construction of custom decks that add valuable outdoor living space and increase the value of your property.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Planting
            </div>

            <div className="service-text">
              Our planting services include design and installation of beautiful and sustainable landscaping that complements the features of your outdoor space.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Irrigation
            </div>

            <div className="service-text">
              We offer custom irrigation solutions to ensure that your landscaping stays healthy and beautiful with minimal maintenance.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Outdoor Kitchens
            </div>

            <div className="service-text">
              We specialize in the design and installation of custom outdoor kitchens that allow you to cook and entertain in style.
            </div>
          </div>
        </div>
      </div>

      <div className="service-outro-wrapper">
        <div className="service-outro-header">
          Contact Us Today for Your Outdoor Construction and Landscaping Needs!
        </div>

        <div className="service-outro-text">
          At our landscaping company, we are dedicated to providing high-quality outdoor construction and landscaping services that exceed your expectations. Whether you're looking to create a cozy patio, or a functional outdoor kitchen, our team of experts has the knowledge and experience to bring your vision to life. Contact us today to schedule a consultation and get started on transforming your outdoor space into the oasis of your dreams.
        </div>

        <NavLink
          to="/book-consultation"
          className="service-outro-action-button"
        >
          Book Now
        </NavLink>
      </div>
    </div>
  )
}

export default OutdoorConstructionAndLandscaping;