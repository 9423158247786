import { useState } from 'react'
import DatePicker, { CalendarContainer } from "react-datepicker";
import { handleSubmitBookingApptForm } from '../../Functions/BookAppt/bookApptFunctions';
import "react-datepicker/dist/react-datepicker.css";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { useEffect } from 'react';
import CelebIconOne from "../../Images/Icons/BookAppt/celebration-one.svg"
import CelebIconTwo from "../../Images/Icons/BookAppt/celebration-two.svg"

const BookAppt = () => {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phoneNum, setPhoneNum ] = useState("");
  const [ address, setAddress ] = useState("");
  const [ date, setDate ] = useState(null);
  const [ lookingFor, setLookingFor ] = useState("");
  const [ loadingSubmission, setLoadingSubmission ] = useState(false);
  const [ successfulSubmission, setSuccessfulSubmission ] = useState(false);
  const [ errors, setErrors ] = useState(false);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  console.log()

  useEffect(() => {
    if (loadingSubmission) {
      handleSubmitBookingApptForm(
        name,
        setName,
        email,
        setEmail,
        phoneNum,
        setPhoneNum,
        address,
        setAddress,
        date,
        setDate,
        lookingFor,
        setLookingFor,
        errors,
        setErrors,
        loadingSubmission,
        setLoadingSubmission,
        successfulSubmission,
        setSuccessfulSubmission
      )
    }
  }, [ loadingSubmission ])

  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer className={className}>
          <div className="date-picker-custom-container" style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <div className="book-appt-wrapper">
      <div className="book-appt-intro-wrapper">
        <div className="book-appt-intro-header">
          Speak with Our Team and Strategize Your Landscaping Needs
        </div>

        <div className="book-appt-intro-text">
          Complete the form for a consultation with our experts.
        </div>
      </div>

      {
        successfulSubmission ? (
          <div className="book-appt-successful-sub-wrapper">
            <div className="book-appt-successful-sub-icon-group-one">
              <div className="book-appt-successful-sub-icon-one-wrapper">
                <img
                  src={CelebIconTwo}
                  className="book-appt-successful-sub-icon-one"
                />
              </div>

              <div className="book-appt-successful-sub-icon-two-wrapper">
                <img
                  src={CelebIconOne}
                  className="book-appt-successful-sub-icon-one"
                />
              </div>
            </div>

            <div className="book-appt-successful-sub-header">
              Consultation Successfully Booked!
            </div>

            <div className="book-appt-successful-sub-text">
              Our team of experts will reach out to you on the scheduled date to discuss your landscaping needs. We appreciate the opportunity to collaborate with you and develop a tailored strategy for your project.
            </div>

            <div className="book-appt-successful-sub-icon-group-two">
              <div className="book-appt-successful-sub-icon-two-wrapper">
                <img
                  src={CelebIconOne}
                  className="book-appt-successful-sub-icon-one"
                />
              </div>
              
              <div className="book-appt-successful-sub-icon-two-wrapper">
                <img
                  src={CelebIconTwo}
                  className="book-appt-successful-sub-icon-two"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="book-appt-main-content-wrapper">
            <div className="book-appt-left-column-wrapper">
              <div className="book-appt-left-column-header">
                Let us help you transform your property.
              </div>

              <div className="book-appt-left-column-text">
                Booking a consultation is quick, easy, and takes no more than 3 minutes to complete.
              </div>

              <div className="book-appt-left-column-reviews-wrapper">
                <div className="book-appt-left-column-review-wrapper">
                  <div className="book-appt-left-column-review-text">
                    "Incredibly professional, easy to schedule and amazing service. I will definitely use E&O for any needs at my home as well as my rental properties."
                  </div>

                  <div className="book-appt-left-column-review-author">
                    - Jamie Moses on Facebook
                  </div>
                </div>

                <div className="book-appt-left-column-review-wrapper">
                  <div className="book-appt-left-column-review-text">
                    "We are so glad to have worked with E&O. Very communicative and professional. So happy with our beautiful backyard space."
                  </div>

                  <div className="book-appt-left-column-review-author">
                    - Mariah Kinsey on Facebook
                  </div>
                </div>

                <div className="book-appt-left-column-review-wrapper">
                  <div className="book-appt-left-column-review-text">
                  "E&O Landscape is exceptional and truly cares about their clients satisfaction. Highly recommended for anyone in Utah looking for a top-tier landscaper."
                  </div>

                  <div className="book-appt-left-column-review-author">
                    - Scott Larson
                  </div>
                </div>
              </div>
            </div>

            <div
              className="book-appt-form-wrapper"
            >
              <div className="book-appt-form-input-group">
                <label
                  htmlFor="book-appt-name-input"
                  className="book-appt-form-label"
                >
                  Name
                </label>

                <input
                  id="book-appt-name-input"
                  className="book-appt-form-input"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Ex: John or John Doe"
                />

                {
                  errors && name.length < 3 ? (
                    <div className="book-appt-form-input-error">
                      {
                        name.length === 0 ? "The field above is required." : "A minumum of 3 characters is required."
                      }
                    </div>
                  ) : null
                }
              </div>

              <div className="book-appt-form-input-group">
                <label
                  htmlFor="book-appt-email-input"
                  className="book-appt-form-label"
                  >
                  Email
                </label>

                <input
                  id="book-appt-email-input"
                  className="book-appt-form-input"
                  type="text"
                  value={email}     
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Ex: johndoe123@example.com"
                />

                {
                  errors && email.length < 3 ? (
                    <div className="book-appt-form-input-error">
                      {
                        email.length === 0 ? "The field above is required." : "Please use a valid email address."
                      }
                    </div>
                  ) : null
                }
              </div>

              <div className="book-appt-form-input-group">
                <label
                  htmlFor="book-appt-phone-input"
                  className="book-appt-form-label"
                >
                  Phone Number
                </label>

                <input
                  id="book-appt-phone-input"
                  className="book-appt-form-input"
                  type="text"
                  value={phoneNum}
                  onChange={(e) => setPhoneNum(e.target.value)}
                  placeholder="Ex: 222-222-2222"
                />

                {
                  errors && phoneNum.length < 10 ? (
                    <div className="book-appt-form-input-error">
                      {
                        phoneNum.length === 0 ? "The field above is required." : "Please use a valid phone number."
                      }
                    </div>
                  ) : null
                }
              </div>

              <div className="book-appt-form-input-group">
                <label
                  htmlFor="book-appt-address-input"
                  className="book-appt-form-label"
                >
                  Address
                </label>

                <input
                  id="book-appt-address-input"
                  className="book-appt-form-input"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Ex: 1234 S 5678 W, Heber"
                />

                {
                  errors && address.length < 3 ? (
                    <div className="book-appt-form-input-error">
                      {
                        address.length === 0 ? "The field above is required." : "Please use a valid phone address."
                      }
                    </div>
                  ) : null
                }
              </div>

              <div className="book-appt-form-input-group">
                <label
                  className="book-appt-form-label"
                >
                  Date
                </label>

                <DatePicker 
                  selected={date} 
                  onChange={(date) => setDate(date)}
                  showIcon
                  dateFormat="MM/dd/yyyy"
                  minDate={new Date()}
                  monthsShown={1}
                  calendarContainer={MyContainer}
                  disabledKeyboardNavigation
                  placeholderText="Select a date"
                  excludeDates={[new Date()]}
                  filterDate={isWeekday}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onFocus={e => e.target.blur()}
                />

                {
                  errors && !date ? (
                    <div className="book-appt-form-input-error">
                      The field above is required.
                    </div>
                  ) : errors && date && date.getHours() === 0 ? (
                    <div className="book-appt-form-input-error">
                      Please select a valid time.
                    </div>
                  ) : null
                }
              </div>

              <div className="book-appt-form-input-group">
                <label
                  htmlFor="book-appt-looking-for-input"
                  className="book-appt-form-label"
                >
                  Tell us a little more about what your looking for
                </label>

                <input
                  id="book-appt-looking-for-input"
                  className="book-appt-form-input"
                  type="text"
                  value={lookingFor}
                  onChange={(e) => setLookingFor(e.target.value)}
                  placeholder="Ex: Irrigation"
                />

                {
                  errors && lookingFor.length < 1 ? (
                    <div className="book-appt-form-input-error">
                      {
                        "The field above is required."
                      }
                    </div>
                  ) : null
                }
              </div>

              {
                errors && errors.length ? (
                  <div className="book-appt-form-input-error">
                    {
                      errors
                    }
                  </div>
                ) : null
              }

              <button
                className="book-appt-form-button"
                onClick={
                  loadingSubmission ? e => e.preventDefault() : 
                  () => setLoadingSubmission(true)
                }
              >
                {
                  loadingSubmission ? 
                  "Booking..." :
                  "Submit"
                }
              </button>
            </div>
          </div>
        )
      }

      <div className="book-appt-outro-wrapper">
        <div className="book-appt-outro-header">
          Discover the Possibilities of Your Property
        </div>

        <div className="book-appt-outro-text">
          Our team of landscaping experts is here to help you bring your vision to life. We offer a variety of services that are tailored to your unique needs, style, and budget. With our professional guidance, you can create a landscape that reflects your personality and lifestyle. Schedule an appointment today to start your journey towards a stunning outdoor oasis."
        </div>
      </div>
    </div>
  )
}

export default BookAppt;