import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  query,
  startAfter,
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "../../Config/firebase";

const handleGettingFirstTimelineEvents = async (setPhotoGalleryData) => {
  const firstPhotoGalleryItemsQuery = query(
    collection(db, "photo-gallery"),
    limit(5)
  );
  const firstPhotoGalleryItemsSnapshots = await getDocs(
    firstPhotoGalleryItemsQuery
  );

  return setPhotoGalleryData(firstPhotoGalleryItemsSnapshots.docs);
};

const handleGettingEventsCount = async (setPhotoGalleryCount) => {
  const photoGalleryColl = collection(db, "photo-gallery");
  const snapshot = await getCountFromServer(photoGalleryColl);

  return setPhotoGalleryCount(snapshot.data().count);
};

const handleGettingMoreData = async (
  setLoadingMoreData,
  photoGalleryData,
  setPhotoGalleryData
) => {
  setLoadingMoreData(true);

  const lastVisibleEvent = photoGalleryData[photoGalleryData.length - 1];

  const newPhotoGalleryItemsQuery = query(
    collection(db, "photo-gallery"),
    startAfter(lastVisibleEvent),
    limit(5)
  );

  const newPhotoGalleryItemsSnapshots = await getDocs(
    newPhotoGalleryItemsQuery
  );

  setPhotoGalleryData((photoGalleryData) => [
    ...photoGalleryData,
    ...newPhotoGalleryItemsSnapshots.docs,
  ]);

  return setLoadingMoreData(false);
};

const handleDeletingPhotoGalleryItem = (item, setSuccessfulDelete) => {
  const beforeImagePath = item.data().beforeImgPathName;
  const afterImagePath = item.data().afterImgPathName;
  deleteDoc(doc(db, "photo-gallery", item.id))
    .then(() => {
      const projectBeforeImageRef = ref(storage, beforeImagePath);
      const projectAfterImageRef = ref(storage, afterImagePath);

      deleteObject(projectBeforeImageRef)
        .then(() => {
          console.log("successful");
        })
        .catch((err) => {
          console.log(err);
        });

      deleteObject(projectAfterImageRef)
        .then(() => {
          return setSuccessfulDelete(true);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export {
  handleGettingFirstTimelineEvents,
  handleGettingEventsCount,
  handleGettingMoreData,
  handleDeletingPhotoGalleryItem,
};
