import React from 'react'

const PageNotFound = () => {
  return (
    <div className="page-not-found-wrapper">
      <div className="page-not-found-header">Error 404</div>

      <div className="page-not-found-text">Page Not Found</div>
    </div>
  )
}

export default PageNotFound;