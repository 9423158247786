import React from 'react'
import EcoIcon from "../../Images/Icons/About/eco-friendly.svg";
import EcoDarkIcon from "../../Images/Icons/About/eco-friendly.svg";
import QualityIcon from "../../Images/Icons/About/quality.svg";
import QualityDarkIcon from "../../Images/Icons/About/quality.svg";
import OutdoorsIcon from "../../Images/Icons/About/outdoors.svg";
import SmileIcon from "../../Images/Icons/About/smile.svg";
import SmileDarkIcon from "../../Images/Icons/About/smile.svg";
import CompassDarkIcon from "../../Images/Icons/About/compass.svg"
import LightbulbDarkIcon from "../../Images/Icons/About/lightbulb.svg"
import HandshakeDarkIcon from "../../Images/Icons/About/handshake.svg"
import AlberHeadshotImage from "../../Images/About/alber.jpeg"
import AlexHeadshotImage from "../../Images/About/alex.jpeg"
import { NavLink } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="about-us-wrapper">
      <div className="about-page-intro-wrapper">
        <div className="about-page-intro">We help you transform your home. <span className="about-page-intro-varient">Together.</span></div>

        <NavLink
          className="about-page-intro-book-now-action-btn"
          to="/book-consultation"
        >
          Book Now
        </NavLink>
      </div>

      <div className="about-page-our-culture-container">
        <div className="about-page-our-culture-header">Our Culture</div>

        <div className="about-page-our-culture-wrapper">
          <div className="about-page-our-culture-items-wrapper">
            <div className="about-page-our-culture-item-wrapper">
              <div className="about-page-our-culture-item-icon-wrapper">
                <img 
                  src={EcoIcon}
                  className="about-page-our-culture-item-icon about-page-our-culture-item-icon-eco"
                />
              </div>

              <div className="about-page-our-culture-item-header">
                Our Passion for Sustainability
              </div>

              <div className="about-page-our-culture-item-text">
                At our company, we're committed to using eco-friendly practices and materials in all of our landscaping projects. We believe that sustainable landscaping not only benefits the environment, but also creates healthier and more beautiful outdoor spaces for our clients to enjoy. From using native plants to reducing water usage, we're always striving to find new ways to make our work more sustainable and environmentally responsible.
              </div>
            </div>

            <div className="about-page-our-culture-item-wrapper">
              <div className="about-page-our-culture-item-icon-wrapper">
                <img 
                  src={QualityIcon}
                  className="about-page-our-culture-item-icon about-page-our-culture-item-icon-quality"
                />
              </div>

              <div className="about-page-our-culture-item-header">
                Our Dedication to Quality
              </div>

              <div className="about-page-our-culture-item-text">
                We take great pride in the quality of our work, and believe that every landscape we create should be a true work of art. Our team of skilled professionals is dedicated to creating outdoor spaces that are not only functional, but also beautiful and timeless. Whether we're designing a custom garden, installing a new patio, or creating a stunning water feature, we approach every project with the same level of care and attention to detail.
              </div>
            </div>

            <div className="about-page-our-culture-item-wrapper">
              <div className="about-page-our-culture-item-icon-wrapper">
                <img 
                  src={SmileIcon}
                  className="about-page-our-culture-item-icon about-page-our-culture-item-icon-smiley"
                />
              </div>

              <div className="about-page-our-culture-item-header">
                Our Commitment to Customer Satisfaction
              </div>

              <div className="about-page-our-culture-item-text">
                At our company, we believe that the key to our success is happy customers. That's why we're committed to providing top-notch customer service throughout every stage of the landscaping process. From the initial consultation to the final walk-through, we'll work closely with you to ensure that your outdoor space exceeds your expectations. We're always happy to answer any questions you have, and we'll do everything we can to make sure you're completely satisfied with our work.
              </div>
            </div>

            <div className="about-page-our-culture-item-wrapper">
              <div className="about-page-our-culture-item-icon-wrapper">
                <img 
                  src={OutdoorsIcon}
                  className="about-page-our-culture-item-icon about-page-our-culture-item-icon-outdoors"
                />
              </div>

              <div className="about-page-our-culture-item-header">
                Our Love for the Outdoors
              </div>

              <div className="about-page-our-culture-item-text">
                We're passionate about the outdoors, and we believe that landscaping is one of the best ways to celebrate the beauty of nature. Whether we're working with a small backyard or a large commercial property, we approach every project with a sense of wonder and respect for the natural world. From the way we design our gardens to the materials we use, we're always looking for ways to bring out the best in the natural environment around us.
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="about-page-hero-wrapper">
        <div className="about-page-hero-background" />

        <div className="about-page-hero-content-container">
          <div className="about-page-hero-content-wrapper">
            <div className="about-page-hero-content-subtext">
              We're a team of dedicated experts who are passionate about creating beautiful outdoor spaces. 
            </div>

            <div className="about-page-hero-content-subtext">
              Our goal is to help you transform your property into a stunning oasis that you can enjoy all year round.
            </div>
          </div>
        </div>
      </div>

      <div className="about-page-values-container">
        <div className="about-page-values-intro-wrapper">
          <div className="about-page-values-intro-header">Our Values</div>

          <div className="about-page-values-intro">
            At E&O Landscape, our culture is guided by our core values of quality, experience, customer service, creativity, sustainability, and collaboration. We believe that by living these values, we can create beautiful and sustainable outdoor spaces while maintaining a positive and collaborative work environment.
          </div>
        </div>

        <div className="about-page-values-wrapper">
          <div className="about-page-value-wrapper">
            <div className="about-page-our-value-icon-wrapper">
              <img 
                src={QualityDarkIcon}
                className="about-page-value-icon about-page-value-icon-quality"
              />
            </div>

            <div className="about-page-value-header">
              Quality: 
            </div>

            <div className="about-page-value-text">
              At E&O Landscape, we are dedicated to providing high-quality landscaping services that exceed our clients' expectations
            </div>
          </div>

          <div className="about-page-value-wrapper">
            <div className="about-page-our-value-icon-wrapper">
              <img 
                src={CompassDarkIcon}
                className="about-page-value-icon about-page-value-icon-compass"
              />
            </div>

            <div className="about-page-value-header">
              Experience:
            </div>

            <div className="about-page-value-text">
              With over 7 years of experience in the industry, our team has the expertise and knowledge to handle any landscaping project.
            </div>
          </div>

          <div className="about-page-value-wrapper">
            <div className="about-page-our-value-icon-wrapper">
              <img 
                src={SmileDarkIcon}
                className="about-page-value-icon about-page-value-icon-smiley"
              />
            </div>

            <div className="about-page-value-header">
              Customer Service:
            </div>

            <div className="about-page-value-text">
              We believe in putting our clients first and strive to provide excellent customer service from start to finish.
            </div>
          </div>

          <div className="about-page-value-wrapper">
            <div className="about-page-our-value-icon-wrapper">
              <img 
                src={LightbulbDarkIcon}
                className="about-page-value-icon about-page-value-icon-creativity"
              />
            </div>

            <div className="about-page-value-header">
              Creativity: 
            </div>

            <div className="about-page-value-text">
              Our team of skilled designers and landscapers are always thinking outside the box to create unique and beautiful outdoor spaces.
            </div>
          </div>

          <div className="about-page-value-wrapper">
            <div className="about-page-our-value-icon-wrapper about-page-value-icon-eco">
              <img 
                src={EcoDarkIcon}
                className="about-page-value-icon"
              />
            </div>

            <div className="about-page-value-header">
              Sustainability:
            </div>

            <div className="about-page-value-text">
              We are committed to using eco-friendly and sustainable practices in all of our landscaping projects.
            </div>
          </div>

          <div className="about-page-value-wrapper">
            <div className="about-page-our-value-icon-wrapper">
              <img 
                src={HandshakeDarkIcon}
                className="about-page-value-icon about-page-value-icon-community"
              />
            </div>

            <div className="about-page-value-header">
              Community: 
            </div>

            <div className="about-page-value-text">
              We are proud to be a part of the local community and are dedicated to giving back through volunteering and supporting local organizations.
            </div>
          </div>
        </div>
      </div>

      <div className="about-page-leadership-wrapper">
        <div className="about-page-leadership-header">
          Our Leadership
        </div>

        <div className="about-page-leadership-leaders-wrapper">
          <div className="about-page-leadership-leader-wrapper">
            <div className="about-page-leadership-leader-image-wrapper">
              <img 
                className="about-page-leadership-leader-image"
                src={AlberHeadshotImage}
              />
            </div>

            <div className="about-page-leadership-leader-text-wrapper">
              <div className="about-page-leadership-leader-name">
                Alber Cornejo
              </div>

              <div className="about-page-leadership-leader-experience">
                Owner
              </div>

              <div className="about-page-leadership-leader-text">
                <div>
                  Growing up in Park City, UT, I witnessed firsthand the value of hard work and determination as my parents worked tirelessly to provide a better life for our family.
                </div>

                <div>
                  As a business owner, my goals extend beyond personal success. I am dedicated to fostering the growth and development of my team, recognizing that they are the backbone of our business.
                </div>

                <div>
                  Landscaping has been a true passion of mine, and I take immense pride in every project I undertake. From lawn transformations to complex installations, I am committed to delivering the highest level of quality and craftsmanship to our clients.
                </div>

                <div>
                  I strongly believe in building meaningful relationships with our clients, based on honesty, integrity, and efficiency. These values have been the foundation of my success in the industry, and I am eager to continue building long-lasting connections with clients throughout Utah.
                </div>
              </div>
            </div>
          </div>

          <div className="about-page-leadership-leader-wrapper">
            <div className="about-page-leadership-leader-image-wrapper">
              <img 
                className="about-page-leadership-leader-image"
                src={AlexHeadshotImage}
              />
            </div>

            <div className="about-page-leadership-leader-text-wrapper">
              <div className="about-page-leadership-leader-name">
                Alex Espinoza
              </div>

              <div className="about-page-leadership-leader-experience">
                Owner
              </div>

              <div className="about-page-leadership-leader-text">
                <div>
                  My name is Alex Espinoza, and I was raised in Heber City, Utah. I am a hard-working and family-oriented individual who values my loved ones and my career above all else.
                </div>

                <div>
                  Growing up in a family that has been in the landscaping industry for over 20 years has allowed me to cultivate my passion for learning and growing in this field. This love of learning ultimately led me to start my own business, as I am always striving to develop personally and professionally.
                </div>

                <div>
                  One of the most satisfying aspects of working in landscaping is seeing a project completely transformed from start to finish. I take great pride in contributing my own skills and hard work to every project we undertake at E&O Landscape.
                </div>

                <div>
                  In addition to my love for the work itself, I founded E&O Landscape to change the term "employees". I am committed to creating an environment where every member of our team feels valued and heard, with the opportunity to contribute their unique skills and ideas to every project we take on.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;