import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { handleGettingEventsCount, handleGettingFirstTimelineEvents, handleGettingMoreData } from '../../Functions/Gallery/galleryFunctions';
import PhotoGalleryItem from './PhotoGalleryItem';

const PhotoGallery = () => {
  const [ photoGalleryData, setPhotoGalleryData] = useState([]);
  const [ photoGalleryCount, setPhotoGalleryCount ] = useState(null);
  const [ loadingMoreData, setLoadingMoreData ] = useState(false);
  const intersectionObserver = createRef(null);

  useEffect(() => {
    handleGettingFirstTimelineEvents(setPhotoGalleryData);
    handleGettingEventsCount(setPhotoGalleryCount);
  }, []);

  const lastElemRef = useCallback((photoGalleryItem) => {
    // disconnect observer if their is already one;
    if (intersectionObserver.current) intersectionObserver.current.disconnect();

    // if maximum collection amount is already fetched dont fetch again
    if (photoGalleryData.length === photoGalleryCount) return;

    // create new intersection observer
    intersectionObserver.current = new IntersectionObserver(photoGalleryObserver => {
      if (photoGalleryObserver[0].isIntersecting) {
        // if intersection observer triggers intersection event get more events
        return handleGettingMoreData(
          setLoadingMoreData,
          photoGalleryData,
          setPhotoGalleryData
        );
      }
    })

    // pass observer the element to observe
    if (photoGalleryItem) intersectionObserver.current.observe(photoGalleryItem)
  }, [ photoGalleryData ])

  return (
    <div className="photo-gallery-wrapper">
      <div className="photo-gallery-intro-wrapper">
        <div className="photo-gallery-header">
          Our Stunning Landscaping Projects
        </div>

        <div className="photo-gallery-text">
            Our project gallery showcases some of our stunning landscaping projects. We're passionate about creating beautiful outdoor spaces that enhance the natural beauty of the surrounding environment. Browse our gallery to get inspired and see how we can transform your property.
        </div>
      </div>

      <div className="photo-gallery-items-wrapper">
        {
          photoGalleryData.length > 0 ? photoGalleryData.map((item, idx) => {
            if (idx + 1 === photoGalleryData.length) {
              return (
                <PhotoGalleryItem key={idx} ref={lastElemRef} item={item} />
              )
            }

            return (
              <PhotoGalleryItem key={idx} item={item} />
            )
          }) : (
            <div className="photo-gallery-items-loading-wrapper">
              <div className="photo-gallery-items-loading-header">
                Gallery Projects Loading
              </div>

              <div className="photo-gallery-items-loading-text">
                Please wait while we load our gallery projects. This may take a moment, but we're working hard to ensure you have the best browsing experience. Thank you for your patience!"
              </div>

              <div className="photo-gallery-items-loading-icon-wrapper">
                <FontAwesomeIcon
                  icon={['fas', 'spinner']}
                  spin={true}
                />
              </div>
            </div>
          )
        }
      </div>

      {
        loadingMoreData ? (
          <div className="photo-gallery-more-items-loading-wrapper">
            <div className="photo-gallery-more-items-loading-header">
              Loading More Photo Gallery Projects
            </div>

            <div className="photo-gallery-more-items-loading-icon-wrapper">
              <FontAwesomeIcon
                icon={['fas', 'spinner']}
                spin={true}
              />
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

export default PhotoGallery;