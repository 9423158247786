import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth } from "../../Config/firebase";
import SideNavProvider from "../../Providers/SideNavProvider";
import AboutUs from "../About/About";
import BookAppt from "../BookAppt/BookAppt";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Login from "../Login/Login";
import Navbar from "../Navbar/Navbar";
import SideNav from "../Navbar/SideNav";
import PageNotFound from "../PageNotFound/PageNotFound";
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import PhotoGalleryForm from "../PhotoGallery/PhotoGalleryForm";
import DesignAndBuild from "../Services/DesignAndBuild";
import MaintenanceAndSnowRemoval from "../Services/MaintenanceAndSnowRemoval";
import OutdoorConstructionAndLandscaping from "../Services/OutdoorConstructionAndLandscaping";
import RockWorkAndExcavation from "../Services/RockWorkAndExcavation";
import Icons from "../Utilities/Icons";
import Financing from "../Financing/Financing";

const App = () => {
  const [user, setUser] = useState(false);
  const [initializing, setInitializing] = useState(true);
  Icons();

  function onAuthStateChanged(user) {
    setUser(user);
    if (initializing) {
      setInitializing(false);
    }
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  });

  return (
    <div>
      <BrowserRouter>
        <SideNavProvider>
          <Navbar />
          <SideNav />
        </SideNavProvider>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/book-consultation" element={<BookAppt />} />
          <Route exact path="/financing" element={<Financing />} />

          <Route
            exact
            path="/services/design-and-build"
            element={<DesignAndBuild />}
          />

          <Route
            exact
            path="/services/rockwork-and-excavation"
            element={<RockWorkAndExcavation />}
          />

          <Route
            exact
            path="/services/outdoor-construction-and-landscaping"
            element={<OutdoorConstructionAndLandscaping />}
          />

          <Route
            exact
            path="/services/maintenance-and-snow-removal"
            element={<MaintenanceAndSnowRemoval />}
          />

          <Route exact path="/photo-gallery" element={<PhotoGallery />} />

          {auth.currentUser ? (
            <Route
              exact
              path="/photo-gallery-form"
              element={<PhotoGalleryForm />}
            />
          ) : null}

          {!auth.currentUser ? (
            <Route exact path="/login" element={<Login />} />
          ) : null}

          <Route path="/:slug" element={<PageNotFound />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
