import React from 'react';
import LandScapeImage from "../../Images/Landscape/landscape-7.jpeg"
import ExcavationSvg from "../../Images/Icons/Excavation.svg";
import RockworkSvg from "../../Images/Icons/Rockwork.svg"
import BuildSvg from "../../Images/Icons/Build.svg"
import DesignSvg from "../../Images/Icons/Design.svg"
import LandscapingSvg from "../../Images/Icons/Landscaping.svg"
import MaintenanceSvg from "../../Images/Icons/Maintenance.svg"
import SnowSvg from "../../Images/Icons/Snow.svg"
import OutdoorSvg from "../../Images/Icons/Outdoor.svg"
import { NavLink } from 'react-router-dom';

const Home = () => {
  return (
    <div className="homepage-wrapper">
      <div className="homepage-home-hero-wrapper">
        <div className="homepage-home-hero-background" />

        <div className="homepage-home-hero-content-container">
          <div className="homepage-home-hero-content-wrapper">
            <h1 className="homepage-home-hero-content-header">
              Make Your Neighbors Green with Envy Using Our Professional Landscaping Services
            </h1>

            <NavLink 
              className="homepage-home-hero-content-subtext"
              to="/book-consultation"
            >
              Book Now
            </NavLink>
          </div>
        </div>

        <div className="homepage-home-hero-image-skew-one"></div>
        <div className="homepage-home-hero-image-skew-two"></div>
      </div>

      <div className="homepage-introduction-section-wrapper">
        <div className="homepage-introduction-container">
          <div className="homepage-introduction-wrapper">
            <h2 className="homepage-introduction-header">
              Transform Your Outdoor Space with Professional Landscaping Services
            </h2>

            <h3 className="homepage-introduction-subheader">
              Enhance Your Property's Beauty and Functionality with Our Expertise
            </h3>

            <div className="homepage-introduction-text">
              Our skilled team of landscapers and designers provide high-quality services for homeowners and businesses. We offer landscape design, installation, maintenance, hardscaping, irrigation, and more. From initial consultation to final installation, we work closely with our clients to ensure that every project meets their needs and exceeds their expectations. Contact us today to schedule a consultation and see how we can help you achieve your landscaping goals.
            </div>
          </div>

          <div className="homepage-introduction-image-wrapper">
            <img
              src={LandScapeImage}
              className="homepage-introduction-image"
            />
          </div>
        </div>

        <div className="homepage-introduction-testimonials-wrapper">
          <div className="homepage-introduction-testimonial-wrapper">
            <div className="homepage-introduction-testimonial-text">
              "I cannot recommend E&O Landscape enough for their exceptional landscaping services. Their team was professional, courteous, and knowledgeable throughout the entire process. They transformed my backyard into a stunning oasis that my family and I love spending time in. Thank you, E&O Landscape!"
            </div>

            <div className="homepage-introduction-testimonial-author">
              - Emily Dane
            </div>
          </div>

          <div className="homepage-introduction-testimonial-wrapper">
            <div className="homepage-introduction-testimonial-text">
              "I was thoroughly impressed with the quality of work provided by E&O Landscape. They went above and beyond to ensure that my commercial property's landscaping was not only beautiful but also functional. Their attention to detail and commitment to customer satisfaction is unparalleled. Highly recommended!"
            </div>

            <div className="homepage-introduction-testimonial-author">
              - David Garcia
            </div>
          </div>

          <div className="homepage-introduction-testimonial-wrapper">
            <div className="homepage-introduction-testimonial-text">
              "I recently hired E&O Landscape for a landscape design project, and I could not be happier with the results. Their team was creative and took my ideas into consideration, resulting in a stunning final product. Their pricing was also very reasonable, and the project was completed in a timely manner. I would definitely use their services again!"
            </div>

            <div className="homepage-introduction-testimonial-author">
              - Maria Rodriguez
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-services-wrapper">
        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={DesignSvg} />
          </div>

          <div className="homepage-service-text">
            Design
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={BuildSvg} />
          </div>

          <div className="homepage-service-text">
            Build
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={RockworkSvg} />
          </div>

          <div className="homepage-service-text">
            Rock Work
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={ExcavationSvg} />
          </div>

          <div className="homepage-service-text">
            Excavation
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={OutdoorSvg} />
          </div>

          <div className="homepage-service-text">
            Outdoor Construction
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={LandscapingSvg} />
          </div>

          <div className="homepage-service-text">
            Landscaping
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={MaintenanceSvg} />
          </div>

          <div className="homepage-service-text">
            Maintenance
          </div>
        </div>

        <div className="homepage-service-wrapper">
          <div className="homepage-service-icon-wrapper">
            <img className="homepage-service-icon" src={SnowSvg} />
          </div>

          <div className="homepage-service-text">
            Snow Removal
          </div>
        </div>
      </div>

      <div className="homepage-outro-wrapper">
        <h2 className="homepage-outro-header">
          Your Premier Landscaping Service Provider
        </h2>

        <div className="homepage-outro-text">
          We are dedicated to providing exceptional landscaping services that exceed your expectations. Whether you are looking for routine lawn care, landscape design and installation, or hardscape construction, our team of professionals is ready to transform your outdoor space into a beautiful oasis.
        </div>
      </div>
    </div>
  )
}

export default Home;

