import React from 'react'
import { NavLink } from 'react-router-dom';
import RockworkIntroImage from "../../Images/Landscape/landscape-12.jpg"

const RockWorkAndExcavation = () => {
 return (
  <div className="service-container">
    <div className="service-intro-container">
      <div className="service-intro-wrapper">
        <div className="service-intro-header">
          Rockwork and Excavation Services for Your Outdoor Projects
        </div>

        <div className="service-intro-text">
          If you're looking for reliable and professional rockwork and excavation services for your property, look no further than our E&O Landscape. We offer a comprehensive range of services that can help you transform your outdoor space, whether you're a homeowner, a business owner, or a contractor. Our team of experts has years of experience in the industry, and we use only the highest quality materials and equipment to ensure that your project is completed on time and on budget.
        </div>
      </div>

      <div className="service-intro-image-wrapper">
        <img
          className="service-intro-image"
          src={RockworkIntroImage}
        />
      </div>
    </div>

    <div className="services-main-content-container">
      <div className="services-main-content-intro-wrapper">
        <div className="services-main-content-intro-header">
          Comprehensive Rockwork and Excavation Services for Your Outdoor Projects
        </div>

        <div className="services-main-content-intro-text">
          We're a one-stop-shop for all your rockwork and excavation needs. Our team of experts has the experience and equipment to handle projects of any size or complexity, from simple retaining walls to complex commercial excavation.
        </div>
      </div>

      <div className="services-main-content-wrapper">
        <div className="service-wrapper">
          <div className="service-header">
            Retaining Walls
          </div>

          <div className="service-text">
            Our retaining wall services include the design, installation, and repair of retaining walls to prevent soil erosion and create functional outdoor spaces.
          </div>
        </div>

        <div className="service-wrapper">
          <div className="service-header">
            Water Features
          </div>

          <div className="service-text">
            We specialize in the design and installation of water features such as ponds, waterfalls, and fountains to add a natural and serene touch to your outdoor space.
          </div>
        </div>

        <div className="service-wrapper">
          <div className="service-header">
            Grading & Excavation
          </div>

          <div className="service-text">
            We offer grading and excavation services to prepare your site for construction, create proper drainage, and ensure a level foundation.
          </div>
        </div>

        <div className="service-wrapper">
          <div className="service-header">
            Demolition
          </div>

          <div className="service-text">
            We provide safe and efficient demolition services for residential and commercial structures, ensuring that your property is cleared and ready for new construction.
          </div>
        </div>

        <div className="service-wrapper">
          <div className="service-header">
            Commercial Excavation
          </div>

          <div className="service-text">
            We offer a full range of commercial excavation services, including site preparation, grading, and utility installation, for large-scale projects.
          </div>
        </div>
      </div>
    </div>

    <div className="service-outro-wrapper">
      <div className="service-outro-header">
        Transform Your Property with Our Rockwork and Excavation Services!
      </div>

      <div className="service-outro-text">
        We are dedicated to providing high-quality rockwork and excavation services that meet your needs and exceed your expectations. Whether you're looking for retaining wall installation, water feature design, house foundation construction, utility line installation, grading and excavation, demolition, or commercial excavation, our team of experts can deliver exceptional results.
      </div>

      <NavLink
        to="/book-consultation"
        className="service-outro-action-button"
      >
        Book Now
      </NavLink>
    </div>
  </div>
  )
}

export default RockWorkAndExcavation;