import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Config/firebase";

const handleClearingForm = (setEmail, setPassword, setSuccessfulSubmission) => {
  setEmail("");
  setPassword("");
  return setSuccessfulSubmission(true);
};

const handleLoggingIn = (
  email,
  setEmail,
  password,
  setPassword,
  setSubmitting,
  setErrors,
  setSuccessfulSubmission
) => {
  setErrors(false);

  if (email.length < 5 || password.length === 0) {
    setSubmitting(false);
    return setErrors(true);
  }

  signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      setSubmitting(false);
      return handleClearingForm(setEmail, setPassword, setSuccessfulSubmission);
    })
    .catch((err) => {
      setSubmitting(false);
      return setErrors([err.message]);
    });
};

export { handleLoggingIn };
