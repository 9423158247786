import React from 'react';
import { NavLink } from 'react-router-dom';
import DesignIntroImage from "../../Images/Landscape/landscape-11.jpg"

const DesignAndBuild = () => {
  return (
    <div className="service-container">
      <div className="service-intro-container design-and-build-service-intro-container">
        <div className="service-intro-image-wrapper design-and-build-service-intro-image-wrapper">
          <img
            className="service-intro-image design-and-build-service-intro-image"
            src={DesignIntroImage}
          />
        </div>

        <div className="service-intro-wrapper design-and-build-service-intro-wrapper">
          <div className="service-intro-header design-and-build-service-intro-header">
            Transform Your Outdoor Space with Our Comprehensive Landscaping Services
          </div>

          <div className="service-intro-text design-and-build-service-intro-text">
            We offer comprehensive landscaping services to help you bring your outdoor space to life. Our team of experienced professionals specializes in creating beautiful and functional landscapes that are tailored to your unique needs and preferences.
          </div>
        </div>
      </div>

      <div className="services-main-content-container design-and-build-services-main-content-container">
        <div className="services-main-content-intro-wrapper">
          <div className="services-main-content-intro-header">
            Our Landscaping Services: From Architecture to Hardscaping Design
          </div>

          <div className="services-main-content-intro-text">
            Enrich your outdoor environment with our array of landscaping services crafted to invigorate your space.
          </div>
        </div>

        <div className="services-main-content-wrapper">
          <div className="service-wrapper">
            <div className="service-header">
              Landscaping Architecture
            </div>

            <div className="service-text">
              Our landscaping architecture services include site analysis, design, and planning to help you achieve a cohesive and functional outdoor space. Our team will work with you to understand your needs and preferences, and develop a design that incorporates the elements you want while maximizing the potential of your property.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Landscaping Design
            </div>

            <div className="service-text">
              Our landscaping design services focus on creating beautiful, sustainable, and low-maintenance outdoor spaces. We use a combination of softscaping and hardscaping elements to create a balanced and visually appealing landscape that meets your needs and suits your style.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              2D/3D Design
            </div>

            <div className="service-text">
              We utilize the latest technology to provide 2D and 3D design services that enable you to visualize your project before it's built. Our team can create detailed and accurate designs that help you make informed decisions about your outdoor space.
            </div>
          </div>

          <div className="service-wrapper">
            <div className="service-header">
              Hardscaping Design
            </div>

            <div className="service-text">
              Our experts create functional and visually appealing outdoor spaces using various hardscaping elements such as patios, walkways, retaining walls, and outdoor living areas. Our team will work with you to understand your needs, preferences, and develop a design that complements the existing features of your property. 
            </div>
          </div>
        </div>
      </div>

      <div className="service-outro-wrapper">
        <div className="service-outro-header">
          Let Us Transform Your Outdoor Space Today!
        </div>

        <div className="service-outro-text">
          We are committed to delivering high-quality design and build services that exceed your expectations. Contact us today to schedule a consultation and start transforming your outdoor space into a beautiful and functional oasis.
        </div>

        <NavLink
          to="/book-consultation"
          className="service-outro-action-button"
        >
          Book Now
        </NavLink>
      </div>
    </div>
  )
}

export default DesignAndBuild;