import React, { useState } from 'react';
import SideNavContext from '../Contexts/SideNavContext';

const SideNavProvider = (props) => {
  const [ isSideNavOpen, setIsSideNavOpen ] = useState(false);

  const state = {
    isSideNavOpen,
    setIsSideNavOpen
  }
  
  return (
    <SideNavContext.Provider value={state}>
      {props.children}
    </SideNavContext.Provider>
  )
}

export default SideNavProvider;