import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { auth } from "../../Config/firebase";
import { handleDeletingPhotoGalleryItem } from "../../Functions/Gallery/galleryFunctions";

const PhotoGalleryItem = React.forwardRef(({ item }, ref) => {
  const [ successfulDelete, setSuccessfulDelete ] = useState(false);

  const {
    beforeImgUrl,
    afterImgUrl
  } = item.data();

  console.log(successfulDelete)

  return (
    <div ref={ ref ? ref : null } className="photo-gallery-item-wrapper">
      {
        auth.currentUser ? (
          <div className="photo-gallery-item-projet-title-wrapper">
            <div
              className="photo-gallery-item-admin-delete"
              onClick={
                () => !successfulDelete? handleDeletingPhotoGalleryItem(item, setSuccessfulDelete) : null
              }
            >


              {
                successfulDelete ? "Successfully Deleted" : (
                  <div className="delete-wrapper">
                    Delete This Gallery Item <FontAwesomeIcon icon={['fas', 'trash']} /> 
                  </div>
                )
              }
            </div>
          </div>
        ) : null
      }

      <div className="photo-gallery-item-image-group-wrapper">
        <div className="photo-gallery-item-image-group">
          <div className="photo-gallery-item-image-group-header">
            Before
          </div>

          <div className="photo-gallery-item-image-wrapper">
            <img
              src={beforeImgUrl}
              className="photo-gallery-item-image"
            />
          </div>
        </div>

        <div className="photo-gallery-item-image-group">
          <div className="photo-gallery-item-image-group-header">
            After
          </div>

          <div className="photo-gallery-item-image-wrapper">
            <img 
              src={afterImgUrl}
              className="photo-gallery-item-image"
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default PhotoGalleryItem;