import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Financing = () => {
  return (
    <div className="financing-container">
      <div className="financing-intro-container">
        <div className="financing-intro-wrapper">
          <div className="financing-intro-header">
            Dreaming of a New Landscape?
          </div>

          <div className="financing-intro-text">
            E&O Landscape makes your outdoor transformation easy. Secure the financing you need and turn your landscape dreams into reality. Apply now and enjoy your space!
          </div>

          <a href="https://www.hfsfinancial.net/promo/660afaf8ae2480564e2a8221" target="_blank" className="financing-intro-action-btn">
            Get Financed
          </a>
        </div>

        <div className="financing-intro-grid-filler" />
      </div>

      <div className="financing-info-wrapper">
        <div className="financing-info-intro-wrapper">
          <div className="financing-info-intro-header">
            Simple Process
          </div>

          <div className="financing-info-intro-text">
            We ensure that your journey to financing is straightforward and hassle-free, so you can get back to planning your space with peace of mind.
          </div>
        </div>

        <div className="financing-info-cards-wrapper">
          <div className="financing-info-card-wrapper">
            <div className="financing-info-card-icon">
              <FontAwesomeIcon icon={['fas', 'bolt-lightning']} />
            </div>

            <div className="financing-info-card-header">
              Easy Apply
            </div>

            <div className="financing-info-card-text">
              Navigate effortlessly through our simplified application process. Quick approvals get you one step closer to your lush lawn.
            </div>

            <a href="https://www.hfsfinancial.net/promo/660afaf8ae2480564e2a8221" target="_blank" className="financing-info-card-action-btn">
              Apply Now
            </a>
          </div>

          <div className="financing-info-card-wrapper">
            <div className="financing-info-card-icon">
              <FontAwesomeIcon icon={['fas', 'puzzle-piece']} />
            </div>

            <div className="financing-info-card-header">
              Flexible Terms
            </div>

            <div className="financing-info-card-text">
              With our variety of payment plans, you can choose what works best for your budget and timeline.
            </div>

            <a href="https://apply.hfsfinancial.net/prequalification?_gl=1*19ws4gv*_gcl_au*MTkzMTEwMDUxMy4xNzEzMDQ0OTk5" target="_blank" className="financing-info-card-action-btn">
              Explore Plans
            </a>
          </div>

          <div className="financing-info-card-wrapper">
            <div className="financing-info-card-icon">
              <FontAwesomeIcon icon={['fas', 'dollar-sign']} />
            </div>

            <div className="financing-info-card-header">
              Instant Quote
            </div>

            <div className="financing-info-card-text">
              Get an immediate financing estimate for your project, without any obligation. Fast, free, and personalized for your needs.
            </div>

            <a href="https://apply.hfsfinancial.net/prequalification?_gl=1*19ws4gv*_gcl_au*MTkzMTEwMDUxMy4xNzEzMDQ0OTk5" target="_blank" className="financing-info-card-action-btn">
              Get Quote
            </a>
          </div>
        </div>
      </div>

      <div className="financing-faq-wrapper">
        <div className="financing-faq-intro-wrapper">
          <div className="financing-faq-intro-header">
            Our FAQ
          </div>

          <div className="financing-faq-intro-text">
            Have questions? Our FAQ provides the answers you need to make informed decisions about your landscaping project financing.
          </div>
        </div>

        <div className="financing-faq-cards-wrapper">
          <div className="financing-faq-card-wrapper">
            <div className="financing-faq-card-header">
              How do I apply?
            </div>

            <div className="financing-faq-card-text">
              Applying is simple! Just click on '<a className="financing-card-link" href="https://www.hfsfinancial.net/promo/660afaf8ae2480564e2a8221" target="_blank">Apply Now</a>' and fill out the short form. We'll guide you through every step of the way.
            </div>
          </div>

          <div className="financing-faq-card-wrapper">
            <div className="financing-faq-card-header">
              What are the rates?
            </div>

            <div className="financing-faq-card-text">
              Rates vary based on loan amount and credit history, but we strive to offer competitive options to fit your financial situation.
            </div>
          </div>

          <div className="financing-faq-card-wrapper">
            <div className="financing-faq-card-header">
              Can I pay off early?
            </div>

            <div className="financing-faq-card-text">
              Absolutely. You have the flexibility to pay off your loan early without any penalty, giving you full control over your finances.
            </div>
          </div>

          <div className="financing-faq-card-wrapper">
            <div className="financing-faq-card-header">
              Who can apply?
            </div>

            <div className="financing-faq-card-text">
              Any homeowner looking to finance their landscaping project can apply. We cater to a wide range of credit scores.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Financing;