import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import LogoImage from "../../Images/Graphics/logo-alt.jpeg"
import { NavLink } from 'react-router-dom';
import { auth } from '../../Config/firebase';

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-logo-image-wrapper">
        <img
          className="footer-logo-image"
          src={LogoImage}
        />
      </div>

      <div className="footer-info-section-wrapper">
        <div className="footer-info-section-trademark-info">
          All trademarks and registered trademarks are the property of their respective owners.
        </div>

        <div className="footer-info-section-copyright-info">
          Copyright ©2023 E&O LANDSCAPE Inc.
        </div>

        <div className="footer-info-section-contact-us-wrapper">
          <div className="footer-info-section-contact-us-header">
            Questions? Call us.
          </div>

          <a
            href="tel:+14359629535"
            className="footer-info-section-contact-us-phone"
          >
            1-435-962-9535
          </a>
        </div>
      </div>

      <div className="footer-links-container">
        <div className="footer-links-header">
          Main Pages
        </div>

        <div className="footer-links-wrapper">
          <NavLink
            className="footer-link-wrapper"
            to="/"
          >
            Home
          </NavLink>

          <NavLink 
            className="footer-link-wrapper"
            to="/about-us"
          >
            About Us
          </NavLink>

          <NavLink 
            className="footer-link-wrapper"
            to="/book-consultation"
          >
            Book Consultation
          </NavLink>
        </div>
      </div>

      <div className="footer-links-container">
        <div className="footer-links-header">
          Services
        </div>

        <div className="footer-links-wrapper">
          <NavLink
            className="footer-link-wrapper"
            to="/services/design-and-build"
          >
            Design & Build
          </NavLink>

          <NavLink 
            className="footer-link-wrapper"
            to="/services/rockwork-and-excavation"
          >
            Rock Work & Excavation
          </NavLink>


          <NavLink 
            className="footer-link-wrapper"
            to="/outdoor-construction-and-landscaping"
          >
            Outdoor Construction & Landscaping
          </NavLink>


          <NavLink 
            className="footer-link-wrapper"
            to="/maintenance-and-snow-removal"
          >
            Maintenance & Snow Removal
          </NavLink>
        </div>
      </div>
      
      <div className="footer-links-group-container">
        <div className="footer-links-container">
          <div className="footer-links-header">
            Resources
          </div>

          <div className="footer-links-wrapper">
            <NavLink 
              className="footer-link-wrapper"
              to="/financing"
            >
              Financing
            </NavLink>
          </div>
        </div>

        <div className="footer-links-container">
          <div className="footer-links-header">
            Our Work
          </div>

          <div className="footer-links-wrapper">
            <NavLink 
              className="footer-link-wrapper"
              to="/photo-gallery"
            >
              Photo Gallery
            </NavLink>
          </div>

          {
            auth.currentUser ? (
              <div className="footer-links-wrapper">
                <NavLink 
                  className="footer-link-wrapper"
                  to="/photo-gallery-form"
                >
                  Project Gallery Form
                </NavLink>
              </div>
            ) : null
          }
        </div>
      </div>

      <div className="footer-socials-and-locations-wrapper">
        <div className="footer-locations-container">
          <div className="footer-locations-header">
            Proudly Serving
          </div>

          <div className="footer-locations-wrapper">
            <div className="footer-location-wrapper">
              <div className="footer-location-icon-wrapper">
                <FontAwesomeIcon icon={['fas', 'location-dot']} />
              </div>

              <div className="footer-location">
                Park City
              </div>
            </div>

            <div className="footer-location-wrapper">
              <div className="footer-location-icon-wrapper">
                <FontAwesomeIcon icon={['fas', 'location-dot']} />
              </div>

              <div className="footer-location">
                Midway
              </div>
            </div>

            <div className="footer-location-wrapper">
              <div className="footer-location-icon-wrapper">
                <FontAwesomeIcon icon={['fas', 'location-dot']} />
              </div>

              <div className="footer-location">
                Heber City
              </div>
            </div>

            <div className="footer-location-wrapper">
              <div className="footer-location-icon-wrapper">
                <FontAwesomeIcon icon={['fas', 'location-dot']} />
              </div>

              <div className="footer-location">
                Kamas
              </div>
            </div>
          </div>
        </div>

        <div className="footer-socials-container">
          <div className="footer-socials-header">
            Follow Us
          </div>

          <div className="footer-socials-wrapper">
            <a
              className="footer-social-wrapper"
              target="_blank"
              href="https://www.facebook.com/EandO.Landscape?mibextid=LQQJ4d"
            >
              <FontAwesomeIcon 
                icon={['fab', 'facebook']}
              />
            </a>

            <a
              className="footer-social-wrapper"
              target="_blank"
              href="https://instagram.com/eando.landscape?igshid=YmMyMTA2M2Y="
            >
              <FontAwesomeIcon 
                icon={['fab', 'instagram']}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;