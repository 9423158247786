import React, { useEffect, useState } from 'react';
import { handleLoggingIn } from '../../Functions/Login/loginFunctions';
import LoginImage from '../../Images/Login/login.jpg';

const Login = () => {
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ submitting, setSubmitting ] = useState(false);
  const [ errors, setErrors ] = useState(false);
  const [ successfulSubmission, setSuccessfulSubmission ] = useState(false);

  useEffect(() => {
    if (submitting) {
      handleLoggingIn(
        email,
        setEmail,
        password,
        setPassword,
        setSubmitting,
        setErrors,
        setSuccessfulSubmission
      )
    }
  }, [ submitting ])

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-image-wrapper">
          <img
            className="login-image"
            src={LoginImage}
          />
        </div>
        
        <div className="login-main-content-wrapper">
          <div className="login-header">
            {
              successfulSubmission ? "Login Successful!" : "Login"
            }
          </div>

          {
            successfulSubmission ? (
              <div className="login-successful-wrapper">
                <div className="login-successful-text">
                  We were able to successfully log you in. Admin forms will now be accessible.
                </div>
              </div>
            ) : (
              <form
                className="login-form-wrapper"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="login-input-group-wrapper">
                  <label
                    htmlFor="login-email-input"
                    className="login-input-label"
                  >
                    Email
                  </label>

                  <input
                    id="login-email-input"
                    placeholder="Email"
                    className="login-input"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />

                  {
                    errors && email.length === 0 ? (
                      <div className="login-input-error">
                        The field above is required.
                      </div>
                    ) : errors && email.length < 5 ? (
                      <div className="login-input-error">
                        Please enter a valid email.
                      </div>
                    ) : null
                  }
                </div>

                <div className="login-input-group-wrapper">
                  <label
                    htmlFor="login-password-input"
                    className="login-input-label"
                  >
                    Password
                  </label>

                  <input
                    id="login-password-input"
                    placeholder="Password"
                    className="login-input"
                    type="text"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />

                  {
                    errors && password.length === 0 ? (
                      <div className="login-input-error">
                        The field above is required.
                      </div>
                    ) : null
                  }
                </div>

                {
                  errors && errors.length ? (
                    <div className="login-error">
                      {errors[0]}
                    </div>
                  ) : null
                }

                <button
                  className="login-button"
                  onClick={
                    submitting ? (e) => e.preventDefault() :
                    () => setSubmitting(true)
                  }
                >
                  {
                    submitting ? "Submitting..." : "Submit"
                  }
                </button>
              </form>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Login;