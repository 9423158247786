import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { storage } from '../../Config/firebase';
import { handleSubmittingGalleryForm, handleUploadingImages, handleUploadingToDatabase } from '../../Functions/Gallery/galleryFormFunctions';
import PhotoGalleryFormImage from '../../Images/ProjectGallery/sunset.jpg';

const PhotoGalleryForm = () => {
  const [ projectTitle, setProjectTitle ] = useState("");
  const [ projectDescript, setProjectDescript ] = useState("");
  const [ projectBeforeImage, setProjectBeforeImage ] = useState(null);
  const [ projectAfterImage, setProjectAfterImage ] = useState(null);
  const [ beforeImageUrl, setBeforeImageUrl ] = useState("");
  const [ afterImageUrl, setAfterImageUrl ] = useState("");
  const [ submitting, setSubmitting ] = useState(false);
  const [ errors, setErrors ] = useState(false);
  const [ successfulSubmission, setSuccessfulSubmission ] = useState(false);

  useEffect(() => {
    if (submitting) {
      handleUploadingImages(
        projectTitle,
        setProjectTitle,
        projectDescript,
        setProjectDescript,
        projectBeforeImage,
        setProjectBeforeImage,
        setBeforeImageUrl,
        setAfterImageUrl,
        projectAfterImage,
        setProjectAfterImage,
        setSubmitting,
        setErrors,
        setSuccessfulSubmission
      )
    }
  }, [ submitting ])

  useEffect(() => {
    if (afterImageUrl) {
      handleUploadingToDatabase(
        projectTitle,
        setProjectTitle,
        projectDescript,
        setProjectDescript,
        setProjectBeforeImage,
        setBeforeImageUrl,
        setAfterImageUrl,
        beforeImageUrl,
        afterImageUrl,
        setProjectAfterImage,
        setSubmitting,
        setErrors,
        projectBeforeImage,
        projectAfterImage,
        setSuccessfulSubmission
      )
    }
  }, [ afterImageUrl ])

  return (
    <div className="photo-gallery-container">
      <div className="photo-gallery-wrapper">
        <div className="photo-gallery-image-wrapper">
          <img
            className="photo-gallery-image"
            src={PhotoGalleryFormImage}
          />
        </div>
        
        <div className="photo-gallery-main-content-wrapper">
          <div className="photo-gallery-header">
            {
              successfulSubmission ? "Project Gallery Submission Successful!" : "Project Gallery Form"
            }
          </div>

          {
            successfulSubmission ? (
              <div className="photo-gallery-successful-wrapper">
                <div className="photo-gallery-successful-text">
                  We were able to successfully submit your project to the project gallery.
                </div>

                <div 
                  className="photo-gallery-successful-btn"
                  onClick={() => setSuccessfulSubmission(false) }
                >
                  upload another project to the gallery?
                </div>
              </div>
            ) : (
              <form
                className="photo-gallery-form-wrapper"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="photo-gallery-input-group-wrapper">
                  <label
                    htmlFor="photo-gallery-project-before-image-input"
                    className="photo-gallery-input-label"
                  >
                    Before Image
                  </label>

                  <input
                    id="photo-gallery-project-before-image-input"
                    type="file"
                    className="photo-gallery-file-input"
                    onChange={(e) => setProjectBeforeImage(e.target.files[0])}
                  />

                  {
                    errors && projectBeforeImage ? (
                      <div className="photo-gallery-input-error">
                        The field above is required.
                      </div>
                    ) : null
                  }
                </div>

                <div className="photo-gallery-input-group-wrapper">
                  <label
                    htmlFor="photo-gallery-project-after-image-input"
                    className="photo-gallery-input-label"
                  >
                    After Image
                  </label>

                  <input
                    id="photo-gallery-project-after-image-input"
                    type="file"
                    className="photo-gallery-file-input"
                    onChange={(e) => setProjectAfterImage(e.target.files[0])}
                  />

                  {
                    errors && projectAfterImage ? (
                      <div className="photo-gallery-input-error">
                        The field above is required.
                      </div>
                    ) : null
                  }
                </div>

                {
                  errors && errors.length ? (
                    <div className="photo-gallery-error">
                      {errors[0]}
                    </div>
                  ) : null
                }

                <button
                  className="photo-gallery-button"
                  onClick={
                    submitting ? (e) => e.preventDefault() :
                    () => setSubmitting(true)
                  }
                >
                  {
                    submitting ? "Submitting..." : "Submit"
                  }
                </button>
              </form>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default PhotoGalleryForm;